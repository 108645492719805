import React from 'react';
import { Link } from 'gatsby';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Grid from '@material-ui/core/Grid';

import ProductsByType from './ProductsByType';

const HomeProducts = ({ products }) => {
  const groupByType = products.reduce((acc, product) => {
    if (!acc[product.type]) {
      acc[product.type] = [product];
    } else {
      acc[product.type].push(product);
    }

    return acc;
  }, {});
  const TYPES = Object.keys(groupByType);

  return (
    <div className="container kos__HomeProducts">
      <h2 className="kos__HomeProducts__title">{'OUR SPRAYERS'}</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <ProductsByType index={0} products={groupByType} TYPES={TYPES} />
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item xs={12} md={5}>
              <ProductsByType
                index={6}
                products={groupByType}
                TYPES={TYPES}
                sm
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <ProductsByType
                index={3}
                products={groupByType}
                TYPES={TYPES}
                sm
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ProductsByType
                index={2}
                products={groupByType}
                TYPES={TYPES}
                sm
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <ProductsByType
                index={4}
                products={groupByType}
                TYPES={TYPES}
                sm
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ProductsByType
                index={5}
                products={groupByType}
                TYPES={TYPES}
                sm
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ProductsByType
                index={1}
                products={groupByType}
                TYPES={TYPES}
                sm
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Link to="/sprayers" className="button is-rounded kos__HomeProducts__Btn">
        <span>{'View all our sprayers'}</span>
        <ChevronRightIcon />
      </Link>
    </div>
  );
};

export default HomeProducts;
