import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import People from '@material-ui/icons/People';
import ThumbUp from '@material-ui/icons/ThumbUp';

import quality from '../../../static/img/quality.svg';
import dependability from '../../../static/img/dependability.svg';
import service from '../../../static/img/service.svg';

export const Divider = props => {
  const { cards } = props;

  return (
    <div className="container jumbotron-divider">
      <Grid
        container
        className="box is-rounded"
        alignItems="center"
        alignContent="center"
        spacing={2}
      >
        <Grid item xs={12} lg={4} className="with-line">
          <Grid container>
            <Grid item xs={3}>
              <img src={quality} className="image" style={{ width: 50 }}></img>
            </Grid>
            <Grid item xs={9}>
              <div className="divider-textbox">
                <p className="title is-4">{cards.quality.title}</p>
                <p className="subtitle is-6">{cards.quality.description}</p>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4} className="with-line">
          <Grid container>
            <Grid item xs={3}>
              <img
                src={dependability}
                className="image"
                style={{ width: 50 }}
              ></img>
            </Grid>
            <Grid item xs={9}>
              <div className="divider-textbox">
                <p className="title is-4">{cards.dependability.title}</p>
                <p className="subtitle is-6">
                  {cards.dependability.description}
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container alignContent="center">
            <Grid item xs={3}>
              <img src={service} className="image" style={{ width: 50 }}></img>
            </Grid>
            <Grid item xs={9}>
              <div className="divider-textbox">
                <p className="title is-4">{cards.service.title}</p>
                <p className="subtitle is-6">{cards.service.description}</p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Divider.propTypes = {
  cards: PropTypes.object
};
