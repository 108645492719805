import React, { Fragment } from 'react';

const ProductsByType = ({ products, TYPES, index, sm }) => {
  const getRamdonImage = () => {
    const random = Math.floor(Math.random() * products[TYPES[index]].length);

    return products[TYPES[index]][random].featured_image;
  };

  return (
    <div
      className={`kos__HomeProducts__Card ${
        sm ? 'kos__HomeProducts__Card--small' : ''
      }`}
    >
      <div className="kos__HomeProducts__ImageWrapper">
        <img
          src={getRamdonImage(products[TYPES[index]])}
          alt={`Main Image ${TYPES[index]}`}
        />
      </div>
      <h3 className="kos__HomeProducts__Title">{TYPES[index]}</h3>
      <p className="kos__HomeProducts__Description">{`${
        products[TYPES[index]].length
      } products`}</p>
    </div>
  );
};

export default ProductsByType;
