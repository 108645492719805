import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Grid from '@material-ui/core/Grid';

export const Jumbotron = props => {
  const { image1 = {}, heading, subheading, description, button } = props;
  const { alt = '', image } = image1;
  const imageStyle = { borderRadius: '5px' };

  return (
    <Grid container className="jumbotron" justify="center" alignItems="center">
      <Grid item xs={11} md={5}>
        <Grid container alignItems="flex-end" direction="column" spacing={1}>
          <Grid item className="jumbotron-title">
            {heading}
          </Grid>
          <Grid item className="jumbotron-subtitle">
            {subheading}
          </Grid>
          <Grid item className="jumbotron-description">
            {description}
          </Grid>
          <Grid item>
            <Link className="jumbotron-button button is-rounded" to="/sprayers">
              {button}
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} md={5} className="jumbotron-hide-mobile">
        {image && image.childImageSharp && (
          <Img
            style={imageStyle}
            fluid={image.childImageSharp.fluid}
            alt={alt}
          />
        )}
      </Grid>
    </Grid>
  );
};
